import React from 'react';
import PropTypes from 'prop-types';

const TimezoneGuesserInput = ({ name }) => (
  <input type="hidden" name={name} value={Intl.DateTimeFormat().resolvedOptions().timeZone} readOnly />
);

TimezoneGuesserInput.propTypes = {
  name: PropTypes.string.isRequired,
};

export default TimezoneGuesserInput;
